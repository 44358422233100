import { defineStore } from 'pinia'
import { useContentStore } from '~/composables/stores/useContentStore.js'

export const useNotionUsersStore = defineStore('notion_users', () => {
    const contentStore = useContentStore()
    const allLoaded = ref(false)
    const loadAllRequest = ref(null)
    const cursor = ref(null)

    const loadAll = async (userFetchUrl) => {
        if (loadAllRequest.value) {
            return loadAllRequest.value
        }
        if (!userFetchUrl) {
            return
        }
        contentStore.startLoading()
        // eslint-disable-next-line
        loadAllRequest.value = new Promise(async (resolve, reject) => {
            try {
                const data = await noteFormsFetch(userFetchUrl, { query: { cursor: cursor.value } })
                if (!data.users.length) {
                    contentStore.stopLoading()
                    cursor.value = null
                    allLoaded.value = true
                    loadAllRequest.value = null
                } else {
                    if (data.cursor) {
                        cursor.value = data.cursor
                        contentStore.save(data.users)
                        loadAllRequest.value = null
                        loadAll(userFetchUrl)
                    } else {
                        contentStore.save(data.users)
                        contentStore.stopLoading()
                        allLoaded.value = true
                        loadAllRequest.value = null
                    }
                }
                resolve()
            } catch (err) {
                useAlert().error('Error loading users.')
                contentStore.stopLoading()
                loadAllRequest.value = null
                reject(err)
            }
        })
    }

    return {
        ...contentStore,
        loadAll,
        allLoaded,
    }
})